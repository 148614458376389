import { titles } from '@/assets/detailJSON/expressTitleJson.js'
import dayjs from 'dayjs'
// 侧边栏控制
export const mixin1 = {
  methods: {
    // 侧边栏滚动控制
    handleScrollByIndex() {
      const asideEle = document.querySelector('#aside')
      var asideContent = document.querySelector('#asideContent')
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var top = 800 // 侧边栏内容到父元素顶部的距离
      if (scrollTop > 0) {
        top = scrollTop - top
        asideContent.style.top = parseInt(top) + 'px'

      }
      if (scrollTop > 480) {
        if (asideContent.style.opacity === '' || asideContent.style.opacity <= 0) {
          let timer = setInterval(() => {
            this.transparency += 0.1
            asideContent.style.opacity = `${this.transparency.toFixed(2)}`
            parseInt(asideContent.style.opacity) > 1 ? clearInterval(timer) : ''
          }, 100);
        }
      } else {
        // asideContent.style.top = '0px'
        if (asideContent.style.opacity > 0) {
          let timer = setInterval(() => {
            this.transparency -= 0.1
            asideContent.style.opacity = `${this.transparency.toFixed(2)}`
            parseInt(asideContent.style.opacity) <= 0 ? clearInterval(timer) : ''
          }, 100);
        }
      }
    },
    handleScroll() {
      const asideEle = document.querySelector('#aside')
      var asideContent = document.querySelector('#asideContent')
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var top = 40 // 侧边栏内容到父元素顶部的距离
      if (scrollTop < asideEle.offsetHeight - asideContent.offsetHeight) {
        top = top + scrollTop
        asideContent.style.top = parseInt(top) + 'px'
      }
    },
  },
}
// 票处理
export const mixin2 = {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      ticketsCopy: [], // 合并之后的一日两日票
      expressTicketAfterArr: []
    }
  },
  methods: {
    // 把同一天的同种类型票合并
    dataHandle() {
      if (this.tickets.length == 0) return
      let expressTicket = [] // 快速票 手环
      this.tickets.forEach((item, index) => {
        if (item.ticketKind === 8) {
          this.tickets.splice(index, 1)
        }
      })
      // 先提取出非1日1.5/2日票再合并
      this.tickets.forEach((item, index) => {
        if (item.ticketKind !== 1 && item.ticketKind !== 2 && item.ticketKind !== 5 && item.ticketKind !== 8 && item.ticketKind !== 7 && item.ticketKind !== 9) {
          expressTicket.push(...this.tickets.splice(index, 1))
        }
      })
      // 提取出快速券和手环之后如果还有一日两票就进行合并
      if (this.tickets.length > 0) {
        let ticketType = this.tickets[0].ticketType
        let ticketKind = this.tickets[0].ticketKind
        console.log(ticketType);
        if (ticketType == '1' || ticketType == '2' || ticketType == '3' || ticketKind == '7') {
          if (ticketType == '1') {
            var adultTicket = {
              price: this.tickets[0]['price'],
              num: this.tickets[0]['num'],
              productId: this.tickets[0]['productId']
            }
            var childTicket = {
              price: 0,
              num: 0,
              productId: -1

            }
            var seniorTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
          } else if (ticketType == '2') {
            var adultTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var seniorTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var childTicket = {
              price: this.tickets[0]['price'],
              num: this.tickets[0]['num'],
              productId: this.tickets[0]['productId']
            }
          } else if (ticketType == '3') {
            var adultTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var childTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var seniorTicket = {
              price: this.tickets[0]['price'],
              num: this.tickets[0]['num'],
              productId: this.tickets[0]['productId']
            }
          } else if (ticketKind == '7') {
            var adultTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var childTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var seniorTicket = {
              price: 0,
              num: 0,
              productId: -1
            }
            var allYearTicket = {
              price: this.tickets[0]['price'],
              num: this.tickets[0]['num'],
              productId: this.tickets[0]['productId']
            }
          }
          // this.tickets[0]['1'] = adultTicket
          // this.tickets[0]['2'] = childTicket
          // this.ticketsCopy.push(this.tickets[0])
          const obj = {
            '1': adultTicket,
            '2': childTicket,
            '3': seniorTicket,
            '4': allYearTicket,
            ticketKind: this.tickets[0].ticketKind,
            travelDate: this.tickets[0].travelDate,
            productId: this.tickets[0]['productId'],
            num: this.tickets[0]['num'],
            price: this.tickets[0]['price'],
            expressTicketIds: this.tickets[0].expressTicketIds ? this.tickets[0].expressTicketIds : [],
            officialName: this.tickets[0].officialName ? this.tickets[0].officialName : '',
            officialIntro: this.tickets[0].officialIntro ? this.tickets[0].officialIntro : '',
            batName: this.tickets[0].batName ? this.tickets[0].batName : '',
          } // 合并之后的数据结构，'1'代表成人票，对象包括价格price、所购数量num，产品id productId，'2'代表儿童票
          this.ticketsCopy.push(obj)
          // 改造第一个之后从第二个开始循环，找同类型且日期相同的进行合并

          for (let i = 1; i < this.tickets.length; i++) {
            let item = this.tickets[i]
            let index = this.targetObjIndex(item, 'ticketKind', 'travelDate', this.ticketsCopy)  // 获取ticketKind相同及日期相同的票的索引
            if (index !== -1) {
              // 如果存在相同的，价格相加，数量相加
              let ticketType = item.ticketType
              let ticketKind = item.ticketKind
              if(ticketKind!==7){
                this.ticketsCopy[index][ticketType]['price'] = item.price
                this.ticketsCopy[index][ticketType]['num'] += item.num
                this.ticketsCopy[index][ticketType]['productId'] = item.productId
              }else{
                this.ticketsCopy[index]['4']['price'] = item.price
                this.ticketsCopy[index]['4']['num'] += item.num
                this.ticketsCopy[index]['4']['productId'] = item.productId
              }
            } else {
              // 如果不存在相同
              let ticketType = item.ticketType
              let ticketKind = item.ticketKind
              // 设置成人票的价格、数量、产品id
              if(ticketKind!==7){
                item[ticketType == 1 ? '1' : '2'] = {
                  price: item.price,
                  num: item.num,
                  productId: item.productId
                }
                item[ticketType == 1 ? '1' : '3'] = {
                  price: item.price,
                  num: item.num,
                  productId: item.productId
                }
               
                // 设置儿童票的价格、数量、产品id，如果不是儿童票则设置价格、数量为0
                item[ticketType == 1 ? '2' : '1'] = {
                  price: 0,
                  num: 0,
                  productId: -1
                }
                item[ticketType == 1 ? '3' : '1'] = {
                  price: 0,
                  num: 0,
                  productId: -1
                }
                item['4'] = {
                  price: 0,
                  num: 0,
                  productId: -1
                }
              }else{
                item['1'] = {
                  price: 0,
                  num: 0,
                  productId: -1
                }
                item['2'] = {
                  price: 0,
                  num: 0,
                  productId: -1
                }
                item['3'] = {
                  price: 0,
                  num: 0,
                  productId: -1
                }

              item['4'] = {
                price: item.price,
                num: item.num,
                productId: item.productId
              }
            }
              this.ticketsCopy.push(item)
            }
          }
        }
      }
      this.tickets.push(...expressTicket) // 添加回快速券和手环
      this.ticketsCopy.push(...expressTicket) // 添加回快速券和手环
      // this.ticketsCopy = this.ticketsCopy.concat(expressTicket)
    },
    // 把票拆为成人儿童票，返回数组
    splitObj(obj) {
      let adultTicket
      let childTicket
      let seniorTicket
      let allYearTicket
    if(obj.ticketKind!==7){
      
      adultTicket = {
        ticketKind: obj.ticketKind,
        travelDate: obj.travelDate,
        ticketType: 1,
        num: obj['1'].num,
        productId: obj['1'].productId,
        price: obj['1'].price
      }
       childTicket = {
        ticketKind: obj.ticketKind,
        travelDate: obj.travelDate,
        ticketType: 2,
        num: obj['2'].num,
        productId: obj['2'].productId,
        price: obj['2'].price
      }
      if (obj['3']) {
        seniorTicket = {
          ticketKind: obj.ticketKind,
          travelDate: obj.travelDate,
          ticketType: 3,
          num: obj['3'].num,
          productId: obj['3'].productId,
          price: obj['3'].price
        }
      }
      return [adultTicket, childTicket, seniorTicket]
    }else{
      adultTicket = {
        ticketKind: obj.ticketKind,
        travelDate: obj.travelDate,
        // ticketType: 1,
        num: obj['1'].num,
        productId: obj['1'].productId,
        price: obj['1'].price
      }
      childTicket = {
        ticketKind: obj.ticketKind,
        travelDate: obj.travelDate,
        // ticketType: 2,
        num: obj['2'].num,
        productId: obj['2'].productId,
        price: obj['2'].price
      }
      if (obj['3']) {
        seniorTicket = {
          ticketKind: obj.ticketKind,
          travelDate: obj.travelDate,
          // ticketType: 3,
          num: obj['3'].num,
          productId: obj['3'].productId,
          price: obj['3'].price
        }
      }
      allYearTicket = {
      ticketKind: obj.ticketKind,
      travelDate: obj.travelDate,
      // ticketType: 3,
      num: obj['4'].num,
      productId: obj['4'].productId,
      price: obj['4'].price
  }
  return [adultTicket, childTicket, seniorTicket,allYearTicket]
      }
    
    },
    // 返回数组里与目标对象的某两个键值相等的对象的索引
    targetObjIndex(targetObj, key1, key2, arr) {
      let objIndex = -1
      arr.map((item, index) => {
        if (targetObj[key1] !== 3 && targetObj[key1] == item[key1] && targetObj[key2] == item[key2]) {
          objIndex = index
        }
      })
      return objIndex
    },
    // 快速券合并
    targetObjIndex2(targetObj, key1, key2, key3, arr) {
      let objIndex = -1
      arr.map((item, index) => {
        if (targetObj.ticketKind === 3 && targetObj[key1] == item[key1] && targetObj[key2] == item[key2] && targetObj[key3] == item[key3]) {
          objIndex = index
        }
      })
      return objIndex
    },
    // 获取产品id对应的本地产品id
    getProductIdMap(id) {
      let obj = process.env.VUE_APP_PRODUCTIDMAP
      obj = eval("(" + obj + ")")
      return obj[id]
    },
    // 转换快速票票名
    conversionTicketName(productId) {
      const id = this.getProductIdMap(productId)
      let title = ''
      titles.map(item => {
        if (item.productId === id) {
          title = item.title
        }
      })
      return title
    },
    //判断日历格是在今天前还是在今天后
    beforeToday(date) {
      let isBeforeToday = false
      const calendarDate = date.split('-').join('') //日历格的日期
      const nowDate = new Date()
      const nowMonth = (nowDate.getMonth() + 1) < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1
      const day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()
      const today = [nowDate.getFullYear(), nowMonth, day].join('') // 今天的日期
      if (parseInt(calendarDate) < parseInt(today)) {
        isBeforeToday = true
      } else {
        isBeforeToday = false
      }
      return isBeforeToday
    }
  },
}

// 手环合并
export const mixin3 = {
  data() {
    return {
      BraceletListAfterArr: [] //合并后的手环数组
    }
  },
  methods: {
    getBraceletDataInMixin() {
    
      if (this.BraceletArr.length == 0) return
      this.BraceletArr.forEach(item => {
        let index = this.targetObjIndex1(item, 'id', 'travelDate', this.BraceletListAfterArr)  // 获取ticketKind相同及日期相同的票的索引
        if (index !== -1) {
          this.BraceletListAfterArr[index]['price'] = item.price
          this.BraceletListAfterArr[index]['num'] += item.num
          this.BraceletListAfterArr[index]['productId'] = item.productId
        } else {
          if (this.BraceletListAfterArr.length === 0) {
            // 合并数组没有数据并且不重复的时候放数组第一个进去
            this.BraceletListAfterArr.push(this.BraceletArr[0])
          } else {
            this.BraceletListAfterArr.push(item)
          }
        }
      })
    },
    // 返回数组里与目标对象的某两个键值相等的对象的索引
    targetObjIndex1(targetObj, key1, key2, arr) {
      let objIndex = -1
      arr.map((item, index) => {
        if (targetObj.ticketKind === 8 && targetObj[key1] == item[key1] && targetObj[key2] == item[key2]) {
          objIndex = index
        }
      })
      return objIndex
    },
  }
}


// 判断购物车日期是否当天之前 或者三个小时
export const ifBeforeToday = () => {
  let orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
  // console.log('app内容',orderInfo);
  let endAddCartDate = localStorage.getItem('endAddCartDate');
  if(orderInfo && orderInfo.orderRequestProductList.length){
    orderInfo.orderRequestProductList.forEach(item => {
      // console.log('item',item);
      // console.log('item',+new Date(item.travelDate));
      let today = dayjs(new Date()).format('YYYY-MM-DD').replace('-','').replace('-','')
      let past = item.travelDate.replace('-','').replace('-','')
      // console.log(today,past);
      if(+today > +past){
        console.log('有今天之前的购物车数据');
        localStorage.setItem('orderInfo','{"orderRequestProductList":[],"currency": 5}')
        localStorage.setItem('oneDayTicketAvailableDate','[]')
        localStorage.setItem('expressTicketAvailableDate','[]')
        localStorage.setItem('twoDayAvailableDate','[]')
      }else if(endAddCartDate && endAddCartDate !=  null && +new Date() > +endAddCartDate) {
        localStorage.setItem('orderInfo','{"orderRequestProductList":[],"currency": 5}')
        localStorage.setItem('oneDayTicketAvailableDate','[]')
        localStorage.setItem('expressTicketAvailableDate','[]')
        localStorage.setItem('twoDayAvailableDate','[]')
        localStorage.removeItem('endAddCartDate');
        console.log('有三小时的数据');
      }
    
    })
  }
}

// 判断购物车日期是否当天之后
export function ifTodayLater() {
  let orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
  // console.log('app内容',orderInfo);
  let bianliang = false;
  if(orderInfo && orderInfo.orderRequestProductList.length){
    orderInfo.orderRequestProductList.forEach(item => {
      // console.log('item',item);
      // console.log('item',+new Date(item.travelDate));
      let today = dayjs(new Date()).format('YYYY-MM-DD').replace('-','').replace('-','')
      let past = item.travelDate.replace('-','').replace('-','')
      // console.log(today,past);
      if(+today <= +past){
        bianliang = true
      }else {
        bianliang = false
      }
    
    })
  }
  return bianliang
}
