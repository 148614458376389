import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui';
import './plugins/elememt'
import 'element-ui/lib/theme-chalk/index.css';
// 全局css默认样式
import './assets/css/global.css'
import eleLocale from 'element-ui/lib/locale';
// iconfont的图标
import './assets/icon/iconfont.css'
// 国际化
import i18n from './lang/index'
// Google Analytics
import VueGtag from "vue-gtag"
// import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'
import vhCheck from "vh-check"; 
vhCheck();
Vue.use(VueGtag, {
  config: {
    id: "UA-191422719-1",
    params: {
      send_page_view: true
    }
  }
}, router)

Vue.config.productionTip = false

Vue.config.lang = 'zh-CN'
eleLocale.i18n((key, value) => i18n.t(key, value))


// 分转换为元过滤器
Vue.filter('formataPrice', function (val) {
  if (typeof val == 'number') {
    return parseFloat(val / 100)
  } else {
    return ''
  }
})
// 价钱加上逗号
Vue.filter('abs', function (val) {
  const str = (val / 100).toFixed(2) + ''
  const intSum = str.substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ',')//取到整数部分
  let dot = str.substring(str.length, str.indexOf("."))//取到小数部分
  if (/\.00/ig.test('.00')) {
    return intSum
  } else {
    return intSum + dot
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
