import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import enLocale from './en'
import jaLocale from './ja'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale
  }
}

export function getLanguage() {
  const chooseLanguage = localStorage.getItem('locale')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}
// 可以在初始化VueI18n实例时，通过 localStorage 来为 locale 对象赋值
const i18n = new VueI18n({
  // locale: localStorage.getItem('locale') || 'ja',
  // locale: getLanguage() || 'ja',
  locale: 'en',
  messages,
  // 屏蔽console的warn
  silentTranslationWarn: true
})

// locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n