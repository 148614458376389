import Vue from 'vue'
import VueRouter from 'vue-router'
const home = () => import('@/views/home/index')
const expressPasses = () => import('@/views/expressPasses/index')
const shopCart = () => import('@/views/shopCart/index')
const checkOut = () => import('@/views/checkout/index')
const orderComplete = () => import('@/views/orderComplete/index')
const orderSearch = () => import('@/views/orderSearch/index')
const paymentResponce = () => import('@/views/paymentResponce/index')
const about = () => import('@/views/about/index')
const privacyPolicy = () => import('@/views/privacyPolicy/index')
const termService = () => import('@/views/termService/index')
const extras = () => import('@/views/extras/index')

// 手机日历弹框
const homeDateDialog = () => import('@/views/home/date-dialog-mb')
const expressPassesDateDialog = () => import('@/views/expressPasses/date-dialog-mb')
const extrasDateDialog = () => import('@/views/extras/date-dialog-mb')
// 手机详情弹框 
const homeDetailDialog = () => import('@/views/home/detail-dialog-mb')
const expressPassesDetailDialog = () => import('@/views/expressPasses/detail-dialog-mb')
const extrasDetailDialog = () => import('@/views/extras/detail-dialog-mb')
// 支付中间页
const checkPay = ()=>import('@/views/checkpay/index')
const original = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return original.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: home,
    name:'index'
  },
  {
    name: 'DateDialog',
    path: '/homeDateDialog',
    component: homeDateDialog
  },
  {
    name: 'DateDialog',
    path: '/homedetaildialog',
    component: homeDetailDialog
  },
  {
    path: "/expressPass",
    component: expressPasses,
    name:'index'
  },
  {
    name: 'DateDialog',
    path: "/expressPassDateDialog",
    component: expressPassesDateDialog
  },
  {
    name: 'DateDialog',
    path: "/expressPassdetaildialog",
    component: expressPassesDetailDialog
  },
  {
    path: "/shopCart",
    component: shopCart
  },
  {
    path: "/checkOut",
    name: 'checkOut',
    component: checkOut
  },
  {
    path: '/orderComplete',
    component: orderComplete
  },
  {
    path: '/orderSearch',
    component: orderSearch
  },
  {
    path: '/payResponce',
    component: paymentResponce,
    name: "paymentResponce"
  },
  {
    path: '/notation',
    component: about,
    name: "about"
  },
  {
    path: '/privacy',
    component: privacyPolicy,
    name: "privacyPolicy"
  },
  {
    path: '/terms',
    component: termService,
    name: "termService"
  },
  {
    path: '/options',
    component: extras,
    name:'index'
  },
  {
    path: '/optionsDateDialog',
    component: extrasDateDialog,
    name: "DateDialog"
  },
  {
    path: '/optionsdetaildialog',
    component: extrasDetailDialog,
    name: "DateDialog"
  },
  {
    path: '/checkpay',
    component: checkPay,
    name: "DateDialog"
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  if (to.fullPath !== '/payResponce') {
    sessionStorage.removeItem('VTpay')
  }
  if (to.fullPath === '/checkOut' || to.fullPath === '/payResponce') {
    if (localStorage.getItem('orderInfo') === null) {
      next({ path: '/' })
    } else {
      next()
    }
  }
  next()
})
export default router
