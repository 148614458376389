// 包括手环产品的详情，两者是差不多相同的东西
export const titles = [
  {
    productId: 1,
    title: "Universal Express Pass 7: Flying Dinosaur & Space Fantasy"
  },
  {
    productId: 2,
    title: "Universal Express Pass 7: SING on Tour"
  },
  {
    productId: 3,
    title: "Universal Express Pass 7: The Flying Dinosaur"
  },
  {
    productId: 4,
    title: "Universal Express Pass 7: Hollywood Dream - The Ride: Backdrop"
  },
  {
    productId: 5,
    title: "Universal Express Pass 7: Space Fantasy - The Ride"
  },
  {
    productId: 6,
    title: "Universal Express Pass 4: Adventure Ride & Show"
  },
  {
    productId: 7,
    title: "Universal Express Pass 4: Fun Ride & Show"
  },
  {
    productId: 8,
    title: "Universal Express Pass 4: The Flying Dinosaur"
  },
  {
    productId: 9,
    title: "Universal Express Pass 4: Despicable Me: Minion Mayhem"
  },
  {
    productId: 10,
    title: "Universal Express Pass 4: Space Fantasy - The Ride"
  },
  {
    productId: 11,
    title: "Universal Express Pass 7: Backdrop"
  },
  {
    productId: 12,
    title: "Universal Express Pass 4: Despicable Me & Space Fantasy"
  },
  {
    productId: 13,
    title: "Universal Express Pass 4: Flying Dinosaur & Space Fantasy"
  },
  {
    productId: 14,
    title: "Universal Express Pass 7: Hollywood Dream - The Ride: Backdrop"
  },
  {
    productId: 15,
    title: "Universal Express Pass 4：Fun Ride"
  },
  {
    productId: 16,
    title: "Universal Express Pass 3：Adventure Ride"
  },
  // 8月新增
  {
    productId: 17,
    title: "Halloween Express Pass 1: Sherlock Holmes"
  },
  {
    productId: 18,
    title: "Halloween Express Pass 2: GeGeGe no Kitaro & Sherlock Holmes "
  },
  {
    productId: 19,
    title: "Universal Express Pass 7: Ride Selection"
  },
  {
    productId: 20,
    title: "Universal Express Pass 7: Ride & Show Selection"
  },
  {
    productId: 21,
    title: "Universal Express Pass 4: Ride Selection"
  },
  {
    productId: 22,
    title: "Universal Express Pass 4: Hollywood Dream - The Ride: Backdrop"
  },
  // 手环产品详情start
  {
    productId: 23,
    title: "Power-Up Band Exchange Ticket"
  },
  {
    productId: 24,
    title: "Power-Up Band Exchange Ticket"
  },
  {
    productId: 25,
    title: "Power-Up Band Exchange Ticket"
  },
  // 手环产品详情end
  // 2021.9.9新增快速券
  {
    productId: 26,
    title: "Universal Express Pass 4: Variety"
  }
];
