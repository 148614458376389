<template>
  <div class="foot-box mt30">
    <div class="content">
      <!-- <div class="content-title flex-space-between">
        <p>
          <span class="blueFont font_weight_700 mr15 font18">Contact Us</span>
          <img src="@/assets/email.svg" ref="chatImg" alt="">
        </p>
        <p class="please-email">
          <span>Need Assistance with your Order ? </span>
          <span>please email <a class="blueFont font_weight_700">booking@usjticketing.com</a></span>
        </p>
      </div> -->
      <div class="flex-space-between foot-nav">
        <div class="content-left">
          TM & © Universal Studios. All rights reserved.<br />
          * Images are for illustrative purposes.<br />
          * Some images on our website were taken or<br />
          created before the implementation of our current<br />
          guidelines for operations and enhanced hygiene.<br />
        </div>
        <div class="content-right">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="font12 font_weight_700 reset-icon-arrow">
            <el-breadcrumb-item :to="{ path: '/terms' }">Terms of Service </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/privacy' }">Privacy Policy </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/notation' }">Notation</el-breadcrumb-item>
            <el-breadcrumb-item></el-breadcrumb-item>
            <!-- <el-breadcrumb-item>Site Map</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="foot-line"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true
    }
  },
  mounted() {},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
 .foot-box{
    width: 100%;
    background-color: white;
    z-index: 1;
    .content{
      width: 1200px;
      margin: 0 auto;
      .content-title{
        border-bottom: 1px solid #d0c7c6;
        padding: 20px 10px;
        .please-email{
          font-size: 16px;
        }
      }
     
    }
  }
  .content-left{
    padding: 0 15px;
    margin-right: 30px;
    font-size: 12px;
    color: #333333;
  }
  .content-right{
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

   .foot-line{
    background-color: #036ce2;
    height: 40px;
  }
  .foot-nav{
    padding: 20px 0;
  }
@media (max-width: 1200px) {
  .foot-box{
    width: 100%;
    z-index: 1;
  .content{
    width: 100%;
    .content-title{
      padding: 20px 10px;
    }
   }
  }
}
::v-deep .el-breadcrumb{
  margin-left: 15px;
}
@media (max-width: 940px) {
  .foot-nav{
    display: flex;
    flex-direction: column;
  }
  .content-right{
    margin-top: 20px;
  }
}
@media (max-width: 575px) {
 .el-breadcrumb{
    display: flex;
    flex-direction: column;
  }
  .content-title{
    display: flex;
    flex-direction: column;
    .please-email{
      display: flex;
      flex-direction: column;
    }
  }
}
@media (max-width: 575px) {
::v-deep .el-breadcrumb__inner{
  line-height: 30px;
  }
}
  ::v-deep .content-right .el-breadcrumb__inner{
    color: rgb(3, 108, 226);
  }
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner{
    color: #036ce2;
    font-weight: bold;
    font-weight: 700;
    &:hover{
      font-weight: inherit;
      color: #036ce2;
      cursor: pointer;
    }
  }
 ::v-deep .el-icon-arrow-right:before{
   color: #3f8cdf;
 }
 ::v-deep .reset-icon-arrow{
   .el-icon-arrow-right:before{
     color: #036ce2;
   }
 }
</style>