/**
 * 根据数组里的对象的某个值来把俩对象放一起
 * @param {*} key
 * @param {*} arrA
 * @param {*} arrB
 */
export function objListConcat(key, arrA, arrB) {
  // 判断某个time值在另一个数组中是否存在，返回数组，第一项存在则为true，第二项是存在的数组下标（不存在就是-1）
  const objExist = (value, arr) => {
    const index = arr.findIndex(item => item[key] == value);
    return [index !== -1, index];
  };
  const singleList = [];
  const unionList = [];
  arrA.map(item => {
    const [flag, index] = objExist(item[key], arrB);
    if (flag) {
      unionList.push({
        adultProduct: item,
        childProduct: arrB[index],
        travelTime: item.travelTime
      });
    } else {
      singleList.push({
        adultProduct: item,
        travelTime: item.travelTime
      });
    }
  });
  arrB.map(item => {
    const [flag] = objExist(item[key], arrA);
    if (!flag) {
      singleList.push({
        childProduct: item,
        travelTime: item.travelTime
      });
    }
  });
  return unionList.concat(singleList);
}
export function seniorListConcat(key, arrA, arrB) {
  let newArr = [];
  // 判断某个time值在另一个数组中是否存在，返回数组，第一项存在则为true，第二项是存在的数组下标（不存在就是-1）
  const objExist = (value, arr) => {
    const index = arr.findIndex(item => item[key] == value);
    return [index !== -1, index];
  };
  const singleList = [];
  const unionList = [];
  arrA.map(item => {
    const [flag, index] = objExist(item[key], arrB);
    if (flag) {
      unionList.push({
        adultProduct: item.adultProduct,
        childProduct: item.childProduct,
        seniorProduct: arrB[index],
        travelTime: item.travelTime
      });
    } else {
      singleList.push({
        adultProduct: item.adultProduct,
        childProduct: item.childProduct,
        travelTime: item.travelTime
      });
    }
  });
  arrB.map(item => {
    const [flag] = objExist(item[key], arrA);
    if (!flag) {
      singleList.push({
        seniorProduct: item,
        travelTime: item.travelTime
      });
    }
  });
  newArr = unionList.concat(singleList);
  newArr.forEach(item => {
    item.adultProduct === undefined ? delete item.adultProduct : "";
    item.childProduct === undefined ? delete item.childProduct : "";
  });
  return newArr;
}
/**
 * 时间显示形式转换，example：2020-12-20转为Dec 20, 2020
 * @param {*} date
 */
export function formatDate(date) {
  var month = date.split("-")[1];
  // 月份的英文配置
  var monthOptions = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
  };
  // 如果月份小于10
  if (parseInt(month) < 10) {
    month = monthOptions["0" + parseInt(month)].substr(0, 3);
  } else {
    month = monthOptions[month].substr(0, 3);
  }
  var year = date.split("-")[0];
  var day = date.split("-")[2];
  return `${month} ${day}, ${year}`;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = "id", pid = "parentId") {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]["children"]) {
        temp[data[k][pid]]["children"] = [];
      }
      if (!temp[data[k][pid]]["_level"]) {
        temp[data[k][pid]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]["children"].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

// 取指定日期N天后或N天后的日期
export function getDateStr(date, days, seperator = "-") {
  const oDate = new Date(date).valueOf();
  let nDate = oDate + days * 24 * 3600 * 1000;
  nDate = new Date(nDate);
  const y = nDate
    .getFullYear()
    .toString()
    .padStart(2, 0);
  const m = (nDate.getMonth() + 1).toString().padStart(2, 0);
  const d = nDate
    .getDate()
    .toString()
    .padStart(2, 0);
  return `${y}${seperator}${m}${seperator}${d}`;
}

// 获取指定日期是星期几
/**
 * @param dateString 日期字符串（如：2020-05-02）
 * @returns {String} 周几（如：周一为1，周二为2）
 */
export function getWeek(dateString) {
  var dateArray = dateString.split("-");
  const date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
  return date.getDay();
}
// 获取指定年份指定月份的最后一天
/**
 * @param year 年份
 * @param month 月份
 * @returns {String} 如：2021-04-30
 */
export function getLastDayOfMonth(year, month) {
  //获取本年本月的第一天日期
  const date = new Date(year, month - 1, "01");
  //设置日期
  date.setDate(1);
  //设置月份
  date.setMonth(date.getMonth() + 1);
  //获取本月的最后一天
  const cdate = new Date(date.getTime() - 1000 * 60 * 60 * 24);
  //返回结果
  return cdate.getDate();
}

// 获取滚动条距离顶部位置
export function getScrollTop() {
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  } else if (document.body) {
    return document.body.scrollTop;
  }
}
// 获取屏幕宽度
export function getScreenWidth() {
  window.onresize = () => {
    return (() => {
      return document.body.clientWidth;
    })();
  };
}

// 判断是否为苹果手机 true为是 flase为否
export function judgeIphone() {
  let userAgentInfo = navigator.userAgent;
  let mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let isIphone = false
  //根据userAgent判断是否是手机
  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobileAgents[v] === 'iPhone' ? isIphone = true : isIphone = false
    }
  }
  return isIphone
}


// 判断是否移动端
export function judgeMobile() {
  let ua = navigator.userAgent;
  let ipad = ua.match(/(iPad).*OS\s([\d_]+)/)
  let isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/)
  let isAndroid = ua.match(/(Android)\s+([\d.]+)/)
  let isMobile = isIphone || isAndroid;
  //判断
  if (isMobile) return isMobile
  if (isIphone) return isIphone
  if (isAndroid) return isAndroid
}

// 隐藏掉某些月份没有第一行的情况 month为切换上下月的月份,monNum为指定月份 
export function judgeMonthFrist(month, monNum) {
  // console.log(month,monNum);
  // let prevArr = document.querySelectorAll('.prev')
  let prevParent = document.querySelectorAll('.prev')[0].parentNode
  // console.log('prevParent',month ,monNum);
  month === monNum ? prevParent.style.display = 'none' : prevParent.style.display = ''
}

/**
 * 计算两个日期之间的天数
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
function getDaysBetween(dateString1, dateString2) {
  var startDate = Date.parse(dateString1);
  var endDate = Date.parse(dateString2);
  if (startDate > endDate) {
    return 0;
  }
  if (startDate == endDate) {
    return 1;
  }
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return Math.round(days);
}

/**
 * 计算两个日期之间的天数 写死
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
export function getDaysBetweenDie(dateString1, dateString2) {
  let mytime = dateFormat1("YYYY-mm-dd", dateString1) //格式化时间
  var startDate = Date.parse(mytime);
  var endDate = Date.parse(dateString2);
  if (startDate > endDate) {
    return 0;
  }
  if (startDate == endDate) {
    return 1;
  }
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return Math.round(days);
}

function dateFormat1(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}
/**
 * 计算两个日期之间的天数 END
 */

//  返回计算两个日期之间的天数的结果
export function returnDateResults(currentDate, targetDate = '') {
  currentDate = dateFormat1("YYYY-mm-dd", currentDate) //格式化时间
  targetDate = targetDate !== '' ? dateFormat1("YYYY-mm-dd", targetDate) : ''//格式化时间
  if (targetDate === '') {
    let endDateYear = Number(currentDate.split('-')[0]) //获取结束时间年份
    let endDateMon = Number(currentDate.split('-')[1]) + 2 //获取结束时间月份 当前月份+2
    let endDateDay = Number(currentDate.split('-')[2]) // 获取结束时间的天数 (当前时间的天数)
    //如果大于12就是下一年
    if (endDateMon > 12) {
      endDateMon -= 12 // 如果当前月份是11月,因11+2=13,又因13等于下一年的1月所以13-12=1;如果当前月份是12月,因12+2=14,又因14等于下一年的2月所以14-12=2
      endDateYear++ //年份增加
    }
    let endDateMonMaxDayDate = getLastDayOfMonth(endDateYear, endDateMon)  //获取结束月份最大天数

    endDateDay > endDateMonMaxDayDate ? endDateDay = endDateMonMaxDayDate : endDateDay = Number(currentDate.split('-')[2]) //如果当前时间的天数大于结束结束时间的天数就获取结束时间月份的最大天数

    endDateMon < 10 ? endDateMon = `0${endDateMon}` : '' //补零
    endDateDay < 10 ? endDateDay = `0${endDateDay}` : '' //补零
    let endDate = `${endDateYear}-${endDateMon}-${endDateDay}` //拼接结束日期
    if (new Date().toString().indexOf('GMT-') !== -1) {
      // 如果客户时区为负数,那么结束日期必须天数+1
      let negativeDay = Number(endDateDay) + 1
      // negativeDay = negativeDay > endDateMonMaxDayDate ? '01' ? ''
      if (negativeDay > endDateMonMaxDayDate) {
        // 如果天数+1之后大于指定日期的最大天数就月份也+1
        let negativeYear = Number(endDateYear)
        let negativeMon = Number(endDateMon) + 1
        negativeDay = '01' //如果大于最大天数就设置为下一月的1号
        if (negativeMon > 12) {
          negativeMon -= 12 // 如果当前月份是11月,因11+2=13,又因13等于下一年的1月所以13-12=1;如果当前月份是12月,因12+2=14,又因14等于下一年的2月所以14-12=2
          negativeYear = endDateYear++ //年份增加
        }
        Number(negativeMon) < 10 ? negativeMon = `0${negativeMon}` : '' //补零
        let negativeDate = `${negativeYear}-${negativeMon}-${negativeDay}`
        return getDaysBetween(currentDate, negativeDate)
      } else {
        Number(negativeDay) < 10 ? negativeDay = `0${negativeDay}` : '' //补零
        return getDaysBetween(currentDate, `${endDateYear}-${endDateMon}-${negativeDay}`)
      }
    }
    return getDaysBetween(currentDate, endDate)
  } else {
    if (new Date().toString().indexOf('GMT-') !== -1) {
      // 时区为负的情况下
      let targetDateYear = Number(targetDate.split('-')[0]) //获取指定时间年份
      let targetDateMon = Number(targetDate.split('-')[1])  //获取指定时间月份
      let targetDateDay = Number(targetDate.split('-')[2]) // 获取指定时间的天数 (指定时间的天数)
      targetDateMon < 10 ? targetDateMon = `0${targetDateMon}` : '' //补零
      targetDateDay < 10 ? targetDateDay = `0${targetDateDay}` : '' //补零
      // 如果客户时区为负数,那么结束日期必须天数+2
      let negativeDay = Number(targetDateDay) + 2
      let targetDateMonMaxDayDate = getLastDayOfMonth(targetDateYear, targetDateMon)  //获取结束月份最大天数
      if (negativeDay > targetDateMonMaxDayDate) {
        // 如果天数+1之后大于指定日期的最大天数就月份也+1
        let negativeYear = Number(targetDate.split('-')[0])
        let negativeMon = Number(targetDate.split('-')[1]) + 1
        negativeDay = '01' //如果大于最大天数就设置为下一月的1号
        if (negativeMon > 12) {
          negativeMon -= 12 // 如果当前月份是11月,因11+2=13,又因13等于下一年的1月所以13-12=1;如果当前月份是12月,因12+2=14,又因14等于下一年的2月所以14-12=2
          negativeYear = targetDateYear + 1//年份增加
        }
        Number(negativeMon) < 10 ? negativeMon = `0${negativeMon}` : '' //补零
        let negativeDate = `${negativeYear}-${negativeMon}-${negativeDay}`
        return getDaysBetween(currentDate, negativeDate)
      } else {
        Number(negativeDay) < 10 ? negativeDay = `0${negativeDay}` : '' //补零
        return getDaysBetween(currentDate, `${targetDateYear}-${targetDateMon}-${negativeDay}`)
      }
    }
    // 获取现在和指定日期的天数
    return getDaysBetween(currentDate, targetDate)
  }
}