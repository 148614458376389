import Vue from 'vue'
import Vuex from 'vuex'
import { getDateStr } from '../utils/util'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    changeHeight: 0,
    loading: false,
    scrollTop: 0, // 滚动条的位置
    maxSaleObj: {},
    stopSale:true,
    shopCart: localStorage.getItem('orderInfo') === null ? [] : JSON.parse(localStorage.getItem('orderInfo')).orderRequestProductList, // 购物车，用于判断先加入购物车的产品是哪个
    oneDayTicketAvailableDate: localStorage.getItem('oneDayTicketAvailableDate') === null ? [] : JSON.parse(localStorage.getItem('oneDayTicketAvailableDate')), // 一日票可选日期
    twoDayAvailableDate: localStorage.getItem('twoDayAvailableDate') === null ? [] : JSON.parse(localStorage.getItem('twoDayAvailableDate')), // 两日票可选日期
    expressTicketAvailableDate: localStorage.getItem('expressTicketAvailableDate') === null ? [] : JSON.parse(localStorage.getItem('expressTicketAvailableDate')), // 快速券可选日期

  },
  mutations: {
    SET_CONTAINER_PADDING: (state, changeHeight) => {
      state.changeHeight = changeHeight
    },
    SET_STOPSALE:(state,val)=>{
      state.stopSale = val
    },
    SET_LOADING_STATUS: (state, status) => {
      state.loading = status
    },
    SET_SCROLLTOP: (state, val) => {
      state.scrollTop = val
    },
    // 更新shopcart
    SET_SHOPCART: function (state) {
      if (localStorage.getItem('orderInfo') === null) {
        state.shopCart = []
      } else {
        state.shopCart = JSON.parse(localStorage.getItem('orderInfo')).orderRequestProductList
      }
    },
    // productItem是包含产品类型(ticketKind)和所选日期(date)的对象
    ADD_TO_SHOPCART: function (state, productItem) {
      state.shopCart.push(productItem)
      this.commit('SET_TICKET_DATE')
    },
    SET_MAX_SALE_DATE: function (state, val) {
      state.maxSaleObj = val
    },
    // 设置1日票、1.5/2日票、快速券的可选日期
    SET_TICKET_DATE: (state) => {
      state.oneDayTicketAvailableDate = []
      state.twoDayAvailableDate = []
      state.expressTicketAvailableDate = []
      state.oneFiveTicketAvailableDate = []
      const shopCart = state.shopCart
      if (shopCart.length > 0) {
        var firstProductDate = shopCart[0].travelDate // 第一个添加的产品的日期
        var firstProductTicketKind = shopCart[0].ticketKind // 第一个添加的产品的类型
        const OneDayTicketIndex = shopCart.findIndex(item => [1, 9].includes(item.ticketKind)) // 是否含1日票，含有为true，否则为false
        const twoDayTicketInex = shopCart.findIndex(item => [2, 5, 7].includes(item.ticketKind)) // 是否含2日票，含有为true，否则为false
        const expressTicketIndex = shopCart.findIndex(item => item.ticketKind === 3) // 是否含快速券，含有为true，否则为false
        const extrasTicketIndex = shopCart.findIndex(item => item.ticketKind === 8) // 是否含手环券，含有为true，否则为false
        // 如果买一日票
        if (OneDayTicketIndex !== -1) {
          // 如果还没买2日,2日可以买跟1日相同的和往后一天的日期,快速券只能买与1日同一天的
          if (twoDayTicketInex === -1) {
            state.oneDayTicketAvailableDate.push(shopCart[OneDayTicketIndex].travelDate)
            state.twoDayAvailableDate.push(shopCart[OneDayTicketIndex].travelDate)
            state.twoDayAvailableDate.push(getDateStr(shopCart[OneDayTicketIndex].travelDate, -1))
            state.expressTicketAvailableDate.push(shopCart[OneDayTicketIndex].travelDate)
          } else {
            // 如果买了两日，限制快速券可选时间为2日的时间及往后一天
            state.expressTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
            state.expressTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
            // 一日可选时间为2日及往后一天
            state.oneDayTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
            state.oneDayTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
            state.twoDayAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
          }
        } else if (twoDayTicketInex !== -1) {
          // 如果购物车有了两日
          state.oneDayTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
          state.oneDayTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
          state.twoDayAvailableDate.push(shopCart[twoDayTicketInex].travelDate) // 2日只能买已经买过的时间
          // 快速券能选择2日的及往后一天的
          state.expressTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
          state.expressTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
        } else if (expressTicketIndex !== -1) {
          // 如果购物车有了快速券，但还没1.5/2日票
          if (twoDayTicketInex === -1) {
            state.oneDayTicketAvailableDate.push(shopCart[expressTicketIndex].travelDate)
            state.expressTicketAvailableDate.push(shopCart[expressTicketIndex].travelDate)
            state.twoDayAvailableDate.push(shopCart[expressTicketIndex].travelDate)
            state.twoDayAvailableDate.push(getDateStr(shopCart[expressTicketIndex].travelDate, -1))
          } else {
            // 如果买了两日，限制快速券可选时间为2日的时间及往后一天
            state.expressTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
            state.expressTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
            // 一日可选时间为2日及往后一天
            state.oneDayTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
            state.oneDayTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
            state.twoDayAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
          }
        }else if (extrasTicketIndex !== -1) {
          // 如果购物车有了手环，但还没1.5/2日票
          if (twoDayTicketInex === -1) {
            state.oneDayTicketAvailableDate.push(shopCart[extrasTicketIndex].travelDate)
            state.expressTicketAvailableDate.push(shopCart[extrasTicketIndex].travelDate)
            state.twoDayAvailableDate.push(shopCart[extrasTicketIndex].travelDate)
            state.twoDayAvailableDate.push(getDateStr(shopCart[extrasTicketIndex].travelDate, -1))
          } else {
            // 如果买了两日，限制快速券可选时间为2日的时间及往后一天
            state.expressTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
            state.expressTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
            // 一日可选时间为2日及往后一天
            state.oneDayTicketAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
            state.oneDayTicketAvailableDate.push(getDateStr(shopCart[twoDayTicketInex].travelDate, 1))
            state.twoDayAvailableDate.push(shopCart[twoDayTicketInex].travelDate)
          }
        }
        // 保存到本地，防止刷新丢失
        localStorage.setItem('oneDayTicketAvailableDate', JSON.stringify(state.oneDayTicketAvailableDate))
        localStorage.setItem('twoDayAvailableDate', JSON.stringify(state.twoDayAvailableDate))
        localStorage.setItem('expressTicketAvailableDate', JSON.stringify(state.expressTicketAvailableDate))
      } else {
        localStorage.setItem('oneDayTicketAvailableDate', '[]')
        localStorage.setItem('twoDayAvailableDate', '[]')
        localStorage.setItem('expressTicketAvailableDate', '[]')
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
