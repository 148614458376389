export default {
  '去机场': 'to airport',
  '从机场出发':'Departing from Airport',
  '请选择': 'Please select',
  '首页': 'Homepage',
  '时刻表~车费': 'Timetable~Bus fare',
  '乘车处指南': 'Travel Information',
  '预定车票': 'Ticket Reservation',
  '信息咨询': 'Information Consultation',
  '乘车说明': 'Instruction',
  '常见问题': 'Q&A',
  '开启您的旅程': 'Begin Your Journey',
  '利木津巴士车票': 'Limousine Bus Ticket',
  '优质的旅行': 'Quality Trip',
  '也需要一张实惠的车票': 'You Also Need An Affordable Ticket',
  '关西利木津路线列表': 'Kansai Limousine Bus Routes List',
  '预期车票': 'Booking',
  '关西机场': 'Kansai Airport',
  '选择区域': 'Select Area',
  '乘车路线': 'Bus Route',
  '乘车人数': 'The Number Of Passenger',
  '乘车总额': 'The Total Bus Fare',
  '费用总额': 'Total Fare',
  '下一步': 'The Next Step',
  '成人': 'Adult',
  '儿童': 'Child',
  '成人票价': 'Adult Fare',
  '儿童票价': 'Child Fare',
  '总额': 'The Total Amount Of',
  '订单信息': 'Booking Information',
  '乘客信息': 'Passenger Detail',
  '乘客类型': 'Passenger Type',
  '手机号码': 'Mobile Number',
  '已支付': 'Paid',
  '取消': 'Cancelled',
  '订单状态': 'Booking Status',
  '填写信息': 'Fill in Information',
  '客户姓名': 'Client Name',
  '请输入您的姓名': 'Please enter your name',
  '电子邮箱': 'e-mail address',
  '请输入您的邮箱号码': 'Please enter your e-mail address',
  '电话号码': 'Phone number',
  '请输入您的电话号码': 'Please enter your phone number',
  '必填': 'required',
  '返回': 'back',
  '支付订单': 'Go to Payment',
  '订单编号': 'Reservation Number',
  '下单时间': 'Order Date and Time',
  '发车方向': 'Destination',
  '支付方式': 'Payment method',
  '人数运费': 'Busfare',
  '费用合计': 'Total Payment',
  '确认支付': 'Confirm Payment',
  '支付详情': 'Payment Details',
  '你已支付': 'You have Paid',
  '支付失败': 'Payment Unsuccesful',
  '查看订单详情': 'View reservation details',
  '取消并返回首页': 'Cancel and go to homepage',
  '用户名不能为空': 'Username cannot be blank',
  '请输入正确的用户名': 'Please enter a valid username',
  '邮箱不能为空': 'mail address cannot be blank',
  '请输入正确的邮箱': 'Please enter valid mail address',
  '电话号码不能为空': 'Phone number can not be blank',
  '请输入正确的电话号码': 'Please enter a valid phone number',
  '下单成功': 'Reservation is Successful',
  '未核销': '未核销',
  '已核销': '已核销',
  '请输入邮箱地址': 'Please enter the e-mail address',
  '请输入正确的邮箱地址': 'Please enter a valid e-mail address',
  '域名不能为空': 'E-mail address cannot be blank',
  '删除': 'Delete',
  '本社及临空营业所 〒598-0048 大阪府泉佐野市临空往来 北2番地12': 'Main Office and Rinku Office 〒598-0048 2-12 Rinku Orai Kita, Izumisano City, Osaka',
  '时刻表・车费': 'TimeTable・Busfare',
  '大阪站前（梅田）': 'Osaka Ekimae（Umeda）',
  '近铁上本町・心斋桥': 'Kintetsu Uehommachi・Shinsaibashi',
  '南港・天保山・日本环球影城': 'Nanko・Tempozan(Kaiyukan)・Universal Studios Japan™',
  '神户': 'Kobe',
  '京都': 'Kyoto',
  '奈良': 'Nara',
  '通过路线一览查找': 'Bus Route Information Search',
  '使用时的注意事项': 'Precautions',
  '购买车票': 'Ticket Purchase',
  '关于预约': 'About Reservation',
  '关于无现金支付': 'About Cash Payment',
  '关于退票': 'About Refund Policy',
  '寄放随身物品': 'Baggage Deposit',
  '行动不便的旅客': 'Passenger with difficulties',
  '携带宠物的旅客': 'Passenger with pet',
  '关于车内文明': 'Boarding Rules',
  'BESTCHOICE！KIX机场巴士': 'BESTCHOICE！KIX Airport Bus',
  '包租巴士': 'Chartered Bus',
  '关西机场乘车处指南': 'Kansai Airport Information Counter',
  '信息咨询・失物招领': 'Contact Us・Lost & Found',
  '网站地图': 'Site Map',
  '公司简介': 'Company Info',
  //以下为新增
  '请先选择目的地':'Please select a destination first',
  '请先选择需要购买的票':'Please select the ticket you need to buy first',
  '无相关产品':'Unrelated product',
  '选择路线': 'Choose route',
  '支付': 'Pay',
  '支付结果': 'Payment result',
  '发起支付失败': 'Failed to initiate payment',
  '名': 'amount'
  }
