<template>
  <div id="app">
    <page-head v-show="this.$route.name!=='DateDialog'" />
    <!-- <keep-alive>  -->
    <router-view />
    <!-- </keep-alive> -->
    <page-foot v-show="this.$route.name!=='DateDialog'" />
    <loading />
    <stop-Sale ref="stopSaleDialog" ></stop-Sale>
  </div>

</template>

<script type="text/javascript" id="hs-script-loader" async defer src="https://js.hs-scripts.com/9227765.js"></script>
<script>
import pageHead from '@/components/page-header'
import pageFoot from '@/components/page-footer'
import loading from '@/components/loading'
import stopSale from '@/components/stopSaleNotice'
import { getMaxSaleTime,getStopSaletime } from '@/api/index'
import { ifBeforeToday } from "@/mixin/index.js";
export default {
  components: { pageHead, pageFoot, loading,stopSale },
  name: "App",
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    this.closeWindow()
    const expires = 10 * 1000 // 设置localstorage有效时间
    const closeTime = parseInt(localStorage.getItem('closeTime')) // 上次关闭窗口的时间
    const nowTime = parseInt(new Date().getTime()) // 本次打开窗口的时间
    // 如果过期了移除上次的本地数据
    if (expires + closeTime < nowTime) {
      // localStorage.removeItem('orderInfo')
      localStorage.removeItem('params')
      localStorage.removeItem('oneDayTicketAvailableDate')
      localStorage.removeItem('twoDayAvailableDate')
      localStorage.removeItem('expressTicketAvailableDate')
      this.$store.commit('SET_TICKET_DATE')
      this.$store.commit('SET_SHOPCART')
    }
    // 判断购物车是否有今天之前的数据
    ifBeforeToday()
    // 获取日历最大销售时间
    getMaxSaleTime().then(res => {
      if (res.data.code === 0) {
        let obj = {
          'oneTicketDeadLine': null,
          'fastTicketDeadLine': null,
          'bracelet': null,
        }
        res.data.data.list.forEach(item => {
          item.ticketKind === 3 ? obj.fastTicketDeadLine = item.sellDeadline : '' // 快速券最大销售时间
          item.ticketKind === 4 ? obj.oneTicketDeadLine = item.sellDeadline : '' // 1日票/1.5日票/2日票最大销售时间
          item.ticketKind === 6 ? obj.bracelet = item.sellDeadline : '' // 手环最大销售时间
        })
        this.$store.commit('SET_MAX_SALE_DATE', obj)
      }
    })
    // 获取停售时间
    getStopSaletime().then(res=>{
      if(res.data.data.length!==0 && res.data.data[0].dataStatus!==5){
        if(location.pathname!=='/orderComplete'){
          localStorage.setItem("stopSale",true); 
      
          this.$refs.stopSaleDialog.init(res.data.data)
        }
      }else{
        localStorage.setItem("stopSale",false); 
        console.log('获取停售错误')
      }
    })
  },
  methods: {
    // 监听窗口关闭并保存上次关闭窗口时间
    closeWindow() {
      window.addEventListener('beforeunload', e => {
        localStorage.setItem('closeTime', new Date().getTime()) // 关闭窗口的时间
      })
    }
  }
}
</script>

<style lang="scss">
@import "./assets/font/font.css";
#app {
  padding-top: 1px;
}
</style>
