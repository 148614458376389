import request from '@/utils/request'

// 获取产品类型（一日、两日、快速等）
export function getTicketKinds() {
  return request({
    url: '/usj/ticket_kinds',
    method: 'get'
  })
}

// 获取快速票或手环列表
export function getFastTicket(params) {
  return request({
    url: '/usj/page',
    method: 'get',
    params
  })
}

// 下单
export function order(params, obj) {
  return request({
    url: '/usj',
    method: 'post',
    data: params,
    headers: obj
  })
}

// 获取日历产品列表
export function getCalendarProductList(params) {
  return request({
    url: '/usj/list',
    method: 'get',
    params
  })
}
// 获取场次
export function getPeriodList(params) {
  return request({
    url: '/usj/loadPeriodList',
    method: 'get',
    params
  })
}

// 获取订单码
export function getCode(id) {
  return request({
    url: `/usj/${id}`,
    method: 'get',
  })
}

// 获取地区
export function getAreaList(params) {
  return request({
    url: `/usj/areaList`,
    method: 'get',
    params
  })
}

// 支付之后轮询订单支付状态
export function payPayOrderStatus(outerOrderId) {
  return request({
    url: `/usj/paypay/${outerOrderId}`,
    method: 'get',
  })
}

// 支付：包含所有方式
export function pays(outerOrderId, params) {
  // params.payType !== 11 || params.payType !== 17  ? delete params.paymentData : ''
  return request({
    url: `/usj/pay/${outerOrderId}`,
    method: 'post',
    params
  })
}

// mdk发起支付
export function mdkPay(data) {
  return request({
    url: `/usj/mdk/pay`,
    method: 'post',
    data
  })
}

// mdk发起支付
export function gmoPay(data) {
  return request({
    url: `/usj/gmo/pay`,
    method: 'post',
    data
  })
}

// 获取notice公告
export function getNotice(params) {
  return request({
    url: `/usj/news`,
    method: 'get',
    params
  })
}

// ApplePay验证商家证书
export function getMerchant_id(data) {
  return request({
    url: `/usj/verify_merchant_id`,
    method: 'post',
    data
  })
}
// ApplePay验证商家证书
export function getMerchant_id_gmo(data) {
  return request({
    url: `/usj/gmo_applepay/merchant`,
    method: 'post',
    data
  })
}

// 获取服务器当前时间
export function getserverLocalTime() {
  return request({
    url: `/joint/app/now`,
    method: 'get',

  })
}

// 获取最大销售时间
export function getMaxSaleTime(params) {
  return request({
    url: `/usj/type_config/page`,
    method: 'get',
    params
  })
}

// 获取验证码
export function getVerificationCode(params) {
  return request({
    url: `/usj/captcha`,
    method: 'get',
    params
  })
}


// 验证是否可支付
export function verifyPayment(outerOrderId){
  return request({
    url:`/usj/pay/chk/${outerOrderId}`,
    method:'get'
  })
}

// 获取订单下单时间
export function getOrderTime(outerOrderId){
  return request({
    url:`/usj/order/time/${outerOrderId}`,
    method:'get'
  })
}


// 获取是否在停售时间
export function getStopSaletime(){
  return request({
    url:`/usj/notice`,
    method:'get'
  })
}

// 检测支付是否成功
export function orderPayCheck(x){
  return request({
    url:`/usj/gmomultipay/${x}`,
    method:'get'
  })
}

// gmo Apple Pay支付
export function gmoApplePay(data){
  return request({
    url:`/usj/gmo/applepay`,
    method:'post',
    data
  })
}

// asuka日志
export function asukaLog(params){
  return request({
    url:`/usj/asuka/log`,
    method:'post',
    params
  })
}

// 日历是否显示NoStock
export function ifNoStock(params){
  return request({
    url:`/usj/stock`,
    method:'get',
    params
  })
}