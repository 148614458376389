import axios from 'axios'
import qs from 'axios'
import i18n from "@/lang";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // headers: {
  //   'Conten-type': 'multiper/form-data'
  // },
  timeout: 30000 // request timeout
})

service.interceptors.request.use(
  config => {
    // config.headers['Accept-Language'] = localStorage.getItem('locale')
    config.headers['Accept-Language'] = 'en'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
