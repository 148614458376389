import Vue from 'vue'
import { Popover, Tabs, TabPane, CheckboxGroup, Checkbox, Progress, Link, Calendar, Loading, Image, Breadcrumb, BreadcrumbItem, Steps, Card, Step, Select, Dialog, Option, Button, Form, FormItem, Table, TableColumn, Input, DatePicker, TimePicker, TimeSelect, Message, MessageBox, Container, Row, Col, InputNumber, Radio, RadioGroup, RadioButton, Carousel, CarouselItem } from 'element-ui'

Vue.use(Popover)
Vue.use(Tabs)
Vue.use(CheckboxGroup)
Vue.use(TabPane)
Vue.use(Checkbox)
Vue.use(Card)
Vue.use(Progress)
Vue.use(Calendar)
Vue.use(Image)
Vue.use(Link)
Vue.use(Steps)
Vue.use(Step)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(Dialog)
Vue.use(TimePicker)
Vue.use(Table)
Vue.use(TableColumn)
// Vue.use(Dropdown)
Vue.use(InputNumber)
// Vue.use(DropdownMenu)
Vue.use(Option)
Vue.use(Select)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
// Vue.use(Main)
Vue.use(Row)
Vue.use(Col)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.prototype.$MessageBox = MessageBox
Vue.prototype.$message = Message
Vue.use(Loading.directive)

Vue.prototype.$warning = msg => {
  return Message({
    message: msg,
    type: 'warning',
    duration: 3000
  })
}

