<template>
  <div v-if="loading" id="loadDiv" class="lds-css">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @keyframes lds-spinner {
    0% {
      /* opacity: 1; */
      background: white;
    }
    100% {
      /* opacity: 0; */
      background: rgb(21,55,93);
    }
  }
 
@-webkit-keyframes lds-spinner {
  0% {
    /* opacity: 1; */
    background: white;
  }
  100% {
    /* opacity: 0; */
    background: rgb(21,55,93);
  }
}
.lds-css {
	position:fixed; 
	top:0;
	width: 100%; 
	height: 100%;
	background-color: rgba(0, 0, 0, .6);
	z-index:9999;
	filter:alpha(Opacity=80);
}
 
.lds-spinner {
  position: relative;
}
.lds-spinner div {
  left: 27px;
  top: 7px;
  position: absolute;
  -webkit-animation: lds-spinner linear 2.8s infinite;
  animation: lds-spinner linear 2.8s infinite;
  background: white;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-transform-origin: 6px 55px;
  transform-origin: 0 20px;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -2.52s;
  animation-delay: -2.52s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
  -webkit-animation-delay: -2.24s;
  animation-delay: -2.24s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
  -webkit-animation-delay: -1.96s;
  animation-delay: -1.96s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
  -webkit-animation-delay: -1.68s;
  animation-delay: -1.68s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -1.12s;
  animation-delay: -1.12s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
  -webkit-animation-delay: -0.84s;
  animation-delay: -0.84s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
  -webkit-animation-delay: -0.56s;
  animation-delay: -0.56s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
  -webkit-animation-delay: -0.28s;
  animation-delay: -0.28s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner {
  width: 55px !important;
  height: 55px !important;
  left:50%;
  top:50%;
  background: rgb(21,55,93);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>