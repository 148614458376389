export default {
  '去机场': '空港着',
  '从机场出发': '空港発',
  '请选择': '選択してください',
  '首页': 'トップページ',
  '时刻表~车费': '時刻表~料金',
  '乘车处指南': '乗り場案内',
  '预订车票': 'チケット予約する',
  '信息咨询': 'お問い合わせ',
  '乘车说明': '乗車ガイド',
  '常见问题': 'よくある質問',
  '开启您的旅程': '楽しい旅を',
  '利木津巴士车票': 'リムジンバス乗車券',
  '优质的旅行': 'ゆったりした素敵な旅',
  '也需要一张实惠的车票': 'お得な切符です',
  '关西利木津路线列表': '関西リムジンバス路線一覧',
  '预期车票': '指定乗車券',
  '关西机场': '関西空港',
  '选择区域': 'エリアを選択',
  '乘车路线': '路線',
  '乘车人数': '乗車人数',
  '乘车总额': '乗車総額',
  '费用总额': '運賃合計',
  '下一步': '次へ',
  '成人': '大人',
  '儿童': '子供',
  '成人票价': '大人料金',
  '儿童票价': '子供料金',
  '总额': '合計',
  '订单信息': 'オーダー情報',
  '乘客信息': 'お客様情報',
  '乘客类型': 'チケットタイプ',
  '手机号码': '携帯番号',
  '已支付': '支払い完了',
  '取消': 'キャンセル',
  '订单状态': 'ステータス',
  '填写信息': '情報を入力してください',
  '客户姓名': 'お名前',
  '请输入您的姓名': 'お名前を入力してください',
  '电子邮箱': 'メールアドレス',
  '请输入您的邮箱号码': 'メールアドレスを入力してください',
  '电话号码': '電話番号',
  '请输入您的电话号码': '電話番号を入力してください',
  '必填': '必須',
  '返回': '戻る',
  '支付订单': 'お支払い',
  '订单编号': 'オーダー番号',
  '下单时间': 'オーダー日時',
  '发车方向': '目的地',
  '支付方式': '決済方法',
  '人数运费': '運賃',
  '费用合计': '運賃合計',
  '确认支付': '支払い確認',
  '支付详情': 'お支払い情報',
  '你已支付': '支払い完了',
  '支付失败': '支払い失敗',
  '查看订单详情': '注文詳細内容',
  '取消并返回首页': 'トップページに戻る',
  '用户名不能为空': '名前をご入力ください',
  '请输入正确的用户名': '有効な名前をご入力ください',
  '邮箱不能为空': 'メールアドレスをご入力ください',
  '请输入正确的邮箱': 'メールアドレスをご入力ください',
  '电话号码不能为空': '電話番号をご入力ください',
  '请输入正确的电话号码': '有効な電話番号をご入力ください',
  '下单成功': 'オーダー完了',
  '未核销': '未認証',
  '已核销': '認証済み',
  '请输入邮箱地址': 'メールアドレスをご入力ください',
  '请输入正确的邮箱地址': '有効なメールアドレスをご入力ください',
  '域名不能为空': '行き先のご入力ください',
  '删除': 'キャンセル',
  '本社及临空营业所 〒598-0048 大阪府泉佐野市临空往来 北2番地12': '本社営業所 〒598-0048 大阪府泉佐野市临空往来 北2番地12',
  '时刻表・车费': '時刻表・料金',
  '大阪站前（梅田）': '大阪駅前(梅田)',
  '近铁上本町・心斋桥': '近鉄上本町・心斎橋',
  '南港・天保山・日本环球影城': '南港・天保山・ユニバーサル・スタジオ・ジャパン',
  '神户': '神戸',
  '京都': '京都',
  '奈良': '奈良',
  '通过路线一览查找': '路線一覧',
  '使用时的注意事项': '注意事項',
  '购买车票': 'チケット購入',
  '关于预约': '予約について',
  '关于无现金支付': '現金以外の支払いについて',
  '关于退票': 'キャンセルについて',
  '寄放随身物品': '荷物お預かり',
  '行动不便的旅客': '障がい者',
  '携带宠物的旅客': 'ペット同行',
  '关于车内文明': '車内について',
  'BESTCHOICE！KIX机场巴士': 'BESTCHOICE！KIX空港バス',
  '包租巴士': '貸切バス',
  '关西机场乘车处指南': '関西空港乗り場案内',
  '信息咨询・失物招领': 'お問い合わせ・お忘れ物',
  '网站地图': 'サイトマップ',
  '公司简介': '会社情報',
  //以下为新增
  '请先选择目的地':'最初に目的地を選択してください',
  '请先选择需要购买的票':'最初に購入する必要のあるチケットを選択してください',
  '无相关产品':'関連製品なし',
  '选择路线': 'ルートを選択',
  '支付': '支払う',
  '支付结果': '支払い結果',
  '发起支付失败': '支払いを開始できませんでした',
  '名': '量'
}
