<template>
  <div class="header-box">
    <div ref="tip" class="tip" v-if="show">
      <span>This booking site is operated by JTRWeb LTD. - an authorised ticket reseller of Universal Studios Japan.</span>
      <span @click="closeTip" class="close"><i class="el-icon-close" /></span>
    </div>
    <div class="content">
      <div class="imgbox">
        <!-- <img @click="$router.push('/')" class="logoImg" src="@/assets/USJ_logo.png" alt=""> -->
        <img @click="toHomeDialog = true" class="logoImg" :class="{'imgBorder': toHomeDialog}" src="@/assets/USJ_logo_2.png" alt="">
        <!-- 端弹框 -->
        <section v-if="toHomeDialog" class="return-modal" style="background-color: rgb(5, 116, 240);">
          <div class="arrow-up" style="border-bottom-color: rgb(5, 116, 240);"></div>
          <p>Are you sure you want to return Home?</p>
          <a href="https://www.usj.co.jp/web/en/us" class="button-return" role="button" type="button" aria-label="RETURN TO HOME" analytics-title="Return Button | RETURN TO HOME" link-title="Nav | Section: Logo | CTA: Return To Home">RETURN TO HOME</a>
          <button @click="toHomeDialog = false" class="button-return-inverted" style="background-color: rgb(255, 255, 255); color: rgb(3, 108, 226);" aria-label="CONTINUE SHOPPING" analytics-title="Continue Button | CONTINUE SHOPPING" link-title="Nav | Section: Logo | CTA: Continue Shopping">CONTINUE SHOPPING</button>
        </section>
        <div v-if="toHomeDialog" @click="toHomeDialog = false" class="overlay"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      toHomeDialog: false
    }
  },
  mounted() {
    this.$store.commit('SET_CONTAINER_PADDING', this.$refs.tip.offsetHeight)
    window.onresize = () => {
      this.$store.commit('SET_CONTAINER_PADDING', this.$refs.tip.offsetHeight)
    }
  },
  methods: {
    closeTip() {
      this.show = false
      this.$store.commit('SET_CONTAINER_PADDING', 0)
    },
    toHome() {
      this.$router.push('/')
      this.toHomeDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background: #005ec7;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#005ec7),
    to(#0574f0)
  );
  background: linear-gradient(to bottom, #005ec7, #0574f0);
  .content {
    height: 90px;
    width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    .imgbox {
      position: relative;
      flex: 1;
      // padding:10px 0;
      display: -webkit-box;
      display: -moz-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      img {
        // width: 115.28px;
        height: 74px;
      }
    }
  }
}
.tip {
  width: 100%;
  line-height: 50px;
  background-color: #f4fcff;
  text-align: center;
  font-size: 20px;
  .close {
    color: #0368d9;
    font-size: 30px;
    margin-left: 16px;
    cursor: pointer;
  }
}
.return-modal {
  position: absolute;
  background: #036ce2;
  color: #fff;
  padding: 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  z-index: 9;
  bottom: -151%;
  left: 0px;
  p {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 16px;
  }
  .arrow-up {
    height: 0;
    width: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #036ce2;
    top: -10px;
    left: 61px;
  }
  .button-return {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 14px 22px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 12px;
    box-sizing: border-box;
    margin: 0 16px 0 0;
    line-height: 1;
    text-align: center;
    width: auto;
  }
  .button-return-inverted {
    border: 1px solid #fff;
    padding: 14px 22px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 12px;
    box-sizing: border-box;
    line-height: 1;
  }
}
.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: 0.2s;
  transition: all 0.2s;
}
.imgBorder {
  border: 1px dashed white;
}
@media (max-width: 1025px) {
  .tip {
    padding: 0 15px;
    line-height: 1.5;
  }
}
@media (max-width: 1200px) {
  .header-box .content .imgbox {
    padding: 0 10px;
  }
}
@media (max-width: 780px) {
  .header-box {
    width: 100%;
    .content {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      height: 70px;
      .imgbox {
        padding: 0;
        img {
          height: 59px;
        }
      }
    }
  }
  .return-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 290px;
    bottom: auto;
    .arrow-up {
      display: none;
    }
    .button-return {
      width: 100%;
      margin-bottom: 16px;
    }
    .button-return-inverted {
      width: 100%;
    }
  }
  .overlay {
    display: inline-block;
  }
}

@media (max-width: 376px) {
  .tip {
    font-size: 14px;
    line-height: inherit;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 5px 0.626rem;
    text-align: left;
  }
}
@media (min-width: 376px) and (max-width: 540px) {
  .tip {
    .close {
      font-size: inherit;
      font-weight: 900;
    }
    i {
      font-weight: 900;
    }
  }
}
</style>